<template>
  <div>
    <div v-if="compressed" class="flex flex-col w-full">
      <div class="text-secondary">
        Solo para pedidos de delivery, introduce el identificador del pedido:
      </div>
      <div class="flex flex-row items-center">
        <div class="mb-4 mt-1 flex-1">
          <n-input
            placeholder="Identificador del pedido"
            @update:value="(value) => $emit('update:value', value)"
          />
        </div>
        <div>
          <c-icon
            @click="showInfo = true"
            name="InfoOutlined"
            class="ml-2 mb-2 text-accent cursor-pointer"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-secondary">
        Introduce el siguiente codigo que encontraras en tu ticket de Glovo,
        UberEats o JustEat:
      </div>
      <div class="mt-5 flex-1">
        <n-input
          placeholder="Codigo del pedido"
          @update:value="(value) => $emit('update:value', value)"
        />
      </div>
      <div class="max-h-full m-auto mt-10">
        <img
          v-if="deliveryOrderTicket === 'lastapp'"
          src="../assets/lastapp-delivery-example.jpeg"
          class="h-32 m-auto"
        />
        <img
          v-else
          src="../assets/otter-delivery-example.png"
          class="h-32 m-auto"
        />
      </div>
    </div>
    <n-drawer v-model:show="showInfo" :height="302" placement="top">
      <n-drawer-content title="Código de pedido">
        <div>
          Introduce el siguiente codigo que encontraras en tu ticket de Glovo,
          UberEats o JustEat:
        </div>
        <div class="max-h-full m-auto mt-3">
          <img
            v-if="deliveryOrderTicket === 'lastapp'"
            src="../assets/lastapp-delivery-example.jpeg"
            class="h-32 m-auto"
          />
          <img
            v-else
            src="../assets/otter-delivery-example.png"
            class="h-32 m-auto"
          />
        </div>
      </n-drawer-content>
    </n-drawer>
  </div>
</template>

<script>
import CIcon from "../../../core-ui/src/components/CIcon.vue";
import api from "@/api.js";
import { mapState } from "pinia";
import { authStore } from "@/stores/auth";

export default {
  name: "DeliveryCodeField",
  props: {
    value: {
      type: String,
      default: "",
    },
    compressed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showInfo: false,
      deliveryOrderTicket: null,
    };
  },
  async mounted() {
    let data = (
      await api.get(`/deliveryOrder/ticket`, {
        params: {
          organizationId: this.organization.id,
        },
      })
    ).data;
    this.deliveryOrderTicket = data?.deliveryOrderTicket || "lastapp";
  },
  methods: {},
  computed: {
    ...mapState(authStore, ["organization"]),
  },
  components: {
    CIcon,
  },
};
</script>

<style scoped></style>
